<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <p class="page-text__text">„Jeśli ty Jezu tego chcesz, ja też tego chcę”.</p>
          <p class="page-text__text">
            Urodziła się 29 października 1971 w Sassello, miejscowości położonej w Apeninach liguryjskich. Jej rodzice
            oczekiwali na narodziny dziecka aż 11 lat.
          </p>
          <p class="page-text__text">
            Imię Chiara, po włosku znaczy jasna, i kiedy się urodziła faktycznie miała jasne i duże oczy, słodki i
            bezpośredni uśmiech. Imię „LUCE”, z włoskiego – światło, nadała jej dopiero później Chiara Lubich, z którą miała
            listowną, głęboką relację jak córka.
          </p>
          <p class="page-text__text">
            Była zdrowa, kochała naturę i zabawę, ale od początku wyróżniała się miłością do „potrzebujących”, którym
            poświęcała uwagę i służyła, często rezygnując z chwil zabawy. Już od lat przedszkolnych wrzucała swoje
            oszczędności do małego pudełeczka dla “murzynków”. Marzyła później, żeby wyjechać do Afryki jako lekarz i leczyć
            dzieci.
          </p>
          <p class="page-text__text">
            Dzień przed Pierwszą Komunią jako prezent otrzymała księgę Ewangelii. Stwierdziła wtedy, że “tak jak łatwo było
            mi się nauczyć się alfabetu, tak samo powinno być łatwo żyć Ewangelią!”
          </p>
          <p class="page-text__text">
            W wieku 9 lat stała się członkinią Ruchu Focolari i stopniowo wprowadzała w niego też rodziców. Od tamtego czasu
            jej życie było nieustannym wysiłkiem, żeby „stawiać Boga na pierwszym miejscu”.
          </p>
          <p class="page-text__text">
            Jednak w wieku 17 lat, niespodziewanie pojawił się przeszywający ból w lewej części pleców. Między badaniami i
            operacjami, które nie pomagały, postępował nowotwór kości.
          </p>
          <p class="page-text__text">
            Z jej twarzy nie znikał szeroki uśmiech; poddawała się bardzo bolesnemu leczeniu i odmawiając morfiny, która
            odbierała jej jasność, ofiarowywała wszystko za Kościół, za młodzież, za niewierzących, za Ruch, za misje…
            pozostając pogodna i silna.
          </p>
          <p class="page-text__text">Powtarzała: „Już nic nie mam, ale mam jeszcze serce i mogę nim kochać”.</p>
          <p class="page-text__text">
            Pokoje, w szpitalu w Turynie i w domu, były miejscami spotkania, apostolatu, jedności. Nawet lekarze, czasami
            niewierzący, zostali ogarnięci pokojem, którym emanowała wokoło i niektórzy zbliżyli się do Boga. Czuli się
            „pociągnięci jak przez magnes” i do dziś ją wspominają, mówią o niej, modlą się do niej.
          </p>
          <p class="page-text__text">
            Aby dobrze żyć chrześcijaństwem, Chiara bardzo doceniała uczestnictwo w codziennej Mszy świętej, podczas, której
            przyjmowała Jezusa, którego tak kochała.
          </p>
          <p class="page-text__text">
            Zmarła o świcie 7 października 1990, po nocy wielkich cierpień. To dzień Najświętszej Maryi Panny Różańcowej.
            Ostatnie jej słowa brzmiały: Mamo, bądź szczęśliwa, bo ja jestem. Cześć”.
          </p>
          <p class="page-text__text">Do zobaczenia na Świętej Górze!</p>
          <div class="btn" style="display: flex">
            <router-link to="/patroni">
              <Btn text="Powrót" :reverse="true" />
            </router-link>
          </div>
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
</style>
